import '../../theme/datepicker.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  languageCode: string
  onContactsFormSendToBackend: ContactsFormSenderBackend
  title?: string
}

export const ContactsForm = memo(function ContactsForm({
  description,
  languageCode,
  onContactsFormSendToBackend,
  title,
}: Props) {
  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Contacts Form',
          })
      }
    },
    [isSendingForm, onContactsFormSendToBackend],
  )

  const { register, formState, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container id="form">
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        <Form
          onSubmit={isSendingForm ? undefined : onSubmit}
          noValidate
          autoComplete={'off'}
        >
          <Spinner className={isSendingForm ? '' : 'hidden'} variant="form" />

          <Box row wrap space="between">
            <Field>
              <Label>{`${useVocabularyData('name', languageCode)}*`}</Label>
              <Input
                error={formState.errors.name !== undefined}
                isDirty={formState.dirtyFields.name}
                placeholder={useVocabularyData(
                  'name-placeholder',
                  languageCode,
                )}
                type="text"
                required={true}
                {...register('name', {
                  required: true,
                  validate: (name) => name.length > 2,
                })}
              />
            </Field>
            <Field>
              <Label>{`${useVocabularyData('lastname', languageCode)}*`}</Label>
              <Input
                error={formState.errors.lastname !== undefined}
                isDirty={formState.dirtyFields.lastname}
                placeholder={useVocabularyData(
                  'lastname-placeholder',
                  languageCode,
                )}
                type="text"
                required={true}
                {...register('lastname', {
                  required: true,
                  validate: (lastname) => lastname.length > 2,
                })}
              />
            </Field>
            <Field>
              <Label>{`${useVocabularyData('email', languageCode)}*`}</Label>
              <Input
                error={formState.errors.email !== undefined}
                isDirty={formState.dirtyFields.email}
                placeholder={useVocabularyData(
                  'email-placeholder',
                  languageCode,
                )}
                type="email"
                required={true}
                {...register('email', {
                  required: true,
                  validate: (email) => EMAIL_REGEX.test(email),
                })}
              />
            </Field>
            <Field>
              <Label>{useVocabularyData('phone', languageCode)}</Label>
              <Input
                type="text"
                placeholder={useVocabularyData(
                  'phone-placeholder',
                  languageCode,
                )}
                {...register('phone')}
              />
            </Field>
            <Field className="large-field">
              <Label>{useVocabularyData('message', languageCode)}</Label>
              <Textarea
                placeholder={useVocabularyData(
                  'message-placeholder',
                  languageCode,
                )}
                {...register('message')}
              />
            </Field>
            <Checkboxes>
              <Checkbox
                error={formState.errors.privacy_policy !== undefined}
                label={useVocabularyData('form-policy-text', languageCode)}
                required={true}
                {...register('privacy_policy', {
                  required: true,
                })}
              />
            </Checkboxes>
            <Button>
              <Input
                name="submit"
                type="submit"
                value={useVocabularyData('submit', languageCode)}
                variant="submit"
              />
            </Button>
          </Box>

          {displaySendErrorFeedback ? (
            <FormMessages
              languageCode={languageCode}
              text={useVocabularyData('form-error-message', languageCode)}
              title={useVocabularyData(
                'form-error-message-title',
                languageCode,
              )}
              type="error"
            />
          ) : null}

          {displaySendConfirmFeedback ? (
            <FormMessages
              languageCode={languageCode}
              text={useVocabularyData(
                'form-confirmation-message',
                languageCode,
              )}
              title={useVocabularyData(
                'form-confirmation-message-title',
                languageCode,
              )}
            />
          ) : null}
        </Form>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  padding: 7.5vw 12.5vw;
  padding-top: 0;
  top: -4.5vw;

  @media (max-width: 1439px) {
    padding: 7.5vw 1.5625rem;
    padding-top: 0;
  }

  @media (max-width: 1199px) {
    padding: 5rem 1.5625rem;
    padding-top: 2.5rem;
    top: 0;
  }
`

const Wrapper = styled.div`
  max-width: 56.875rem;
  margin: 0 auto;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.43vw;
  line-height: 1.058;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
  }
`

const Description = styled.div`
  margin-top: 2.5vw;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    font-size: 0.8125rem;
  }
`

const Form = styled.form`
  margin-top: 4vw;
  position: relative;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Box = styled(FlexBox)``

const Field = styled.div`
  width: calc(50% - 1.5625rem);
  margin-top: 2.5rem;
  text-align: left;

  @media (max-width: 767px) {
    width: 100%;
  }

  &.large-field {
    width: 100%;
  }

  &.small-field {
    width: calc(25% - 3.125rem);

    @media (max-width: 767px) {
      width: calc(50% - 1.5625rem);
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.625rem;
  margin-left: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

const Checkboxes = styled.div`
  margin: 3.125rem auto 0;
`

const Button = styled.div`
  width: 100%;
`
