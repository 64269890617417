import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Email, Phone, Pin } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  emailWellness?: string
  emailRestaurant?: string
  languageCode: string
  phone?: string
  title?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  emailWellness,
  emailRestaurant,
  languageCode,
  phone,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <Contacts>
          {address ? (
            <Contact>
              <Icon>
                <Pin />
              </Icon>
              <Text dangerouslySetInnerHTML={{ __html: address }} />
            </Contact>
          ) : null}
          {phone ? (
            <Contact>
              <Icon>
                <Phone />
              </Icon>
              <AnchorText
                href={`tel:${phone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                {useVocabularyData('phone', languageCode)}: {phone}
              </AnchorText>
            </Contact>
          ) : null}
          {email && emailWellness && emailRestaurant ? (
            <Contact>
              <Icon>
                <Email />
              </Icon>
              <AnchorText
                href={`mailto:${email}`}
                target="_blank"
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {email}
              </AnchorText>
              <AnchorText
                href={`mailto:${emailWellness}`}
                target="_blank"
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {emailWellness}
              </AnchorText>
              <AnchorText
                href={`mailto:${emailRestaurant}`}
                target="_blank"
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                {emailRestaurant}
              </AnchorText>
            </Contact>
          ) : null}
        </Contacts>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  padding: 7.5vw 12.5vw;

  @media (max-width: 1439px) {
    padding: 7.5vw 1.5625rem;
  }

  @media (max-width: 1199px) {
    padding: 5rem 1.5625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.43vw;
  line-height: 1.27;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
  }
`

const Contacts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5vw;

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;

  @media (max-width: 991px) {
    width: 100%;

    &:not(:first-of-type) {
      margin-top: 2.5rem;
    }
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75vw;
  height: 3.75vw;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 50%;

  @media (max-width: 1199px) {
    width: 2.8125rem;
    height: 2.8125rem;
  }

  svg {
    width: auto;
    height: 1.25vw;

    @media (max-width: 1199px) {
      height: 1rem;
    }
  }
`

const Text = styled.div`
  margin-top: 1.5625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 0.8125rem;
  }
`

const AnchorText = styled.a`
  margin-top: 1.56vw;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;
  text-align: center;
  transition: opacity 0.25s ease-in-out;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
    font-size: 0.8125rem;
  }

  &:not(:first-of-type) {
    margin-top: 0;
  }

  &:hover {
    opacity: 0.6;
  }
`
