import styled from '@emotion/styled'
import { ContactsIntro } from 'app/components/ContactsIntro'
import { Hero } from 'app/components/Hero'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { ContactsForm } from 'app/containers/ContactsForm'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import {
  ContactsFormSenderBackend,
  ContactsFormSenderBackendConfiguration,
} from 'app/utils/ContactsFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  contactsFormSenderBackendConfiguration: ContactsFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function ContactsPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      ContactsFormSenderBackend(
        pageContext.contactsFormSenderBackendConfiguration,
      ),
    [pageContext.contactsFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero {...context.heroProps} languagePrefix={context.languagePrefix} />
      ) : null}
      <div className="wrapper">
        <img src="/arc_hero.svg" alt="" />
        {context.contactsIntroProps ? (
          <div className="background">
            <div className="background__color">
              <ContactsIntro {...context.contactsIntroProps} />
            </div>
          </div>
        ) : null}
        {context.formProps ? (
          <div className="background-dark">
            <img
              src="/arc_top_dark.svg"
              alt=""
              style={{ backgroundColor: '#ffffff' }}
            />
            <div className="background-dark__color">
              <ContactsForm
                {...context.formProps}
                onContactsFormSendToBackend={async (data) =>
                  await sendFormToBackend(data)
                }
              />
            </div>
          </div>
        ) : null}
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            {...context.footerProps}
          />
        ) : null}
      </div>
    </Container>
  )
})

const Container = styled.main``
